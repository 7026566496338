<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-start" @click="back">
      <i class="el-icon-arrow-left"></i>
      {{ $t('chong-zhi') }}
    </div>
    <div class="edit-form">
      <el-form :model="form" ref="form">
        <el-form-item :label="$t('chong-zhi-bi-zhong')" prop="coinType">
          <el-select v-model="form.address" @change="changeType">
            <el-option
              v-for="(item, i) in addressList"
              :key="i"
              :value="item.RechargeAddress"
              :label="item.RechargeNetwork"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="" prop="qrcode">
          <div class="flex-center-start">
            <div class="qrcode" ref="qrcode" id="qrcode"></div>
            <div class="flex-column-center ml-20">
              <el-button>{{ $t('bao-cun-er-wei-ma') }}</el-button>
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('chong-zhi-di-zhi')" prop="address">
          <el-input
            readonly
            v-model="form.address"
            :placeholder="$t('qing-shu-ru-chong-zhi-di-zhi')"
          >
            <template slot="suffix">
              <el-button type="text" class="copy-icon" @click="copyData">{{
                $t('fu-zhi')
              }}</el-button>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('shu-liang')" prop="number" required>
          <el-input
            v-model="form.number"
            :placeholder="$t('qing-shu-ru-shu-liang')"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('fu-kuan-zheng-ming-shang-chuan-fu-kuan-ming-xi-jie-tu')"
          prop="img"
          required
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="uploadUrl"
            name="file"
            :headers="headers"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="form.logo" :src="getBaseUrl(form.logo)" class="avatar" />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="" prop="">
          <el-button class="submit-btn" @click="submit">{{
            $t('que-ding')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import goodsList from '@/components/goodsList'
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
import { getToken } from '@/utils/auth'
import { getRechargeInfo, addRecharge } from '@/api/user'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    goodsList
  },
  data() {
    return {
      form: {
        coinType: 'btc',
        address: ''
      },
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      showPassword: false,
      headers: {},
      addressList: []
    }
  },
  computed: {
    realNumber() {
      return this.form.number || '0.00'
    },
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {
      getRechargeInfo().then((res) => {
        this.addressList = res.data.Items

        let addressData = this.addressList[0]
        if (addressData) {
          this.form.address = addressData.RechargeAddress
          let qrcode = new QRCode(this.$refs.qrcode, {
            text: this.form.address,
            width: 120,
            height: 120,
            correctLevel: QRCode.CorrectLevel.H
          })
        }
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    back() {
      this.$router.go(-1)
    },
    changeType(val) {
      this.resetQrcode()
    },
    changeLink(val) {
      let index = this.linkList.findIndex((v) => {
        return v.key == val
      })
      let data = this.linkList[index]
      this.form.network = data.key
      let addressData = this.addressList.find(
        (v) => v.RechargeNetwork == this.form.network
      )
      if (addressData) {
        this.form.address = addressData.RechargeAddress
        this.resetQrcode()
      }
    },
    resetQrcode() {
      let dom = document.getElementById('qrcode')
      if (dom) {
        dom.innerHTML = ''
      }
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: this.form.address,
        width: 120,
        height: 120,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    copyData() {
      let data = this.form.address
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$message.success(this.$t('fu-zhi-cheng-gong'))
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    uploadSuccess(file) {
      this.form.logo = file.data.FileName
    },
    submit() {
      addRecharge({
        Amount: parseFloat(this.form.number),
        PaymentVoucher: this.form.logo
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('ti-jiao-cheng-gong'))
          this.form.logo = ''
          this.form.number = ''
          this.$refs.form.resetFields()
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>